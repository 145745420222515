<template>
  <section class="hero is-secondary has-bg-img is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">

        <div class="columns is-centered is-multiline">
          <div class="column is-4">
            <figure class="image is-inline-block">
              <img class="is-rounded" src="@/assets/images/logos/logotranspa.png" />
            </figure>
          </div>
        </div>

        <h2 class="subtitle is-size-3 p-1"></h2>
        <h2 class="subtitle is-size-2 p-5 benne">Cette page est actuellement en maintenance. Elle n'est pour le moment pas disponible.</h2>

        <b-button
          @click="$router.push('/')"
          type="is-cream is-large has-text-dark"
        >
          <span class="bree">Retour à la page d'accueil</span>
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url("../assets/images/general/bannieres/carrelets_deux.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
