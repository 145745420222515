<template>
  <div id="app">
    <div id="nav">
      <app-navbar></app-navbar>
    </div>
    <router-view />
    <div id="footer">
      <app-footer v-if="!homepage" />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'
import Footer from '@/components/partials/Footer'
export default {
  components: {
    'app-navbar': Navbar,
    'app-footer': Footer
  },
  computed: {
    homepage () {
      return this.$route.path === '/'
    }
  }
}
</script>

<style>
</style>
