<template>
  <section>
    <section class="hero is-secondary has-bg-img is-medium">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">Essences Tropicales</h1>
          <h2 class="subtitle heading">
            Voici la sélection des essences Tropicales que j'utilise à
            l'atelier.
          </h2>
        </div>
      </div>
    </section>

    <div class="box cta">
      <p class="has-text-centered">
        <span class="tag is-danger">Attention</span> Les pièces de bois
        utilisées pour vos projets ne sont pas toujours disponibles dans toutes
        les tailles mais il m'est également possible d'en commander.
        <router-link to="contact">Contactez-moi pour plus d'infos</router-link>
      </p>
    </div>

    <section>
      <div class="container">
        <h1 class="title">Essences Européennes</h1>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><router-link to="catalogue">Catalogue</router-link></li>
            <li class="is-active">
              <a href="#" aria-current="page">Essences Européennes</a>
            </li>
          </ul>
        </nav>

        <!-- Cartes objets -->
        <div class="columns">
          <div class="columns is-multiline">
            <div class="column is-4">
            <wood
              :title="'Padouk'"
              :subtitle="'Acer pseudoplatanus'"
              :images="[
                {
                  image: require('@/assets/images/creations/essences/europeennes/erable/erable.jpg'),
                  tag: 'Acer pseudoplatanus'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/erable/erable2.jpg'),
                  tag: 'Acer pseudoplatanus'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/erable/erable3.jpg'),
                  tag: 'Acer pseudoplatanus'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/erable/erable4.jpg'),
                  tag: 'Acer pseudoplatanus'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/erable/erable5.jpg'),
                  tag: 'Acer pseudoplatanus'
                }
              ]"
              :description="
                `L’érable sycomore est une espèce d'arbres de grande taille de la famille des acéracées
              fréquent en Europe. Il s'agit d'un arbre à croissance rapide les premières années. L'érable plane ou érable platane dont le
              bout des feuilles est plus acéré. On l'appelle parfois faux platane, grand érable, ou érable de montagne, plus rarement érable blanc.
              C’est un bois dur, de belle finition. Du fait de son homogénéité et de sa fermeté, il est
              l’une des essence donnant la meilleure précision et la meilleure qualité de finition lors des opérations de tournage.`
              "
            />
            </div>
            <div class="column is-4">
            <wood
              :title="'Amarante'"
              :subtitle="'Quercus petraea'"
              :images="[
                {
                  image: require('@/assets/images/creations/essences/europeennes/chene/chene_1.jpg'),
                  tag: 'Quercus Petraea'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/chene/chene2.jpg'),
                  tag: 'Quercus Petraea'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/chene/chene3.jpg'),
                  tag: 'Quercus Petraea'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/chene/chene4.jpg'),
                  tag: 'Quercus Petraea'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/chene/chene5.jpg'),
                  tag: 'Quercus Petraea'
                },
              ]"
              :description="
                `Le terme Chêne est le nom commun de nombreuses espèces d'arbres et d'arbustes appartenant au genre Quercus.
              Ce genre, présent dans tout l'hémisphère nord et dont l'aire de répartition s'étend depuis les froides latitudes jusqu'aux zones tropicales de l'Asie et de l'Amérique.
              Les chênes sont des arbres à bois très dur. Il s'agit d'un matériau très résistant. Par ailleurs, sa résistance
              aux insectes et aux champignons est très importante grâce à sa forte teneur en tanin.`
              "
            />
            </div>
            <div class="column is-4">
            <wood
              :title="'Ebène'"
              :subtitle="'Fagus Sylvatica'"
              :images="[
                {
                  image: require('@/assets/images/creations/essences/europeennes/hetre/hetre1.jpg'),
                  tag: 'Fagus Sylvatica'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/hetre/hetre2.jpg'),
                  tag: 'Fagus Sylvatica'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/hetre/hetre3.jpg'),
                  tag: 'Fagus Sylvatica'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/chene/chene4.jpg'),
                  tag: 'Fagus Sylvatica'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/chene/chene5.jpg'),
                  tag: 'Fagus Sylvatica'
                },
              ]"
              :description="
                `Le hêtre commun, Fagus Sylvatica, couramment désigné simplement comme le hêtre est une espèce d'arbres à feuilles caduques, indigène d'Europe, appartenant à la famille des Fagaceae, tout comme le chêne
              et le chataignier. Le hêtre est un arbre droit, élancé et recouvert d'une écorce lisse, pouvant atteindre une hauteur de 30 à 50 mètres.`
              "
            />
            </div>
            <div class="column is-4">
            <wood
              :title="'Grenadille ou Ebène du Mozambique'"
              :subtitle="'Fraxinus excelsior'"
              :images="[
                {
                  image: require('@/assets/images/creations/essences/europeennes/frene/frene1.jpg'),
                  tag: 'Fraxinus excelsior'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/frene/frene2.jpg'),
                  tag: 'Fraxinus excelsior'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/frene/frene3.jpg'),
                  tag: 'Fraxinus excelsior'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/frene/frene4.jpg'),
                  tag: 'Fraxinus excelsior'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/frene/frene5.jpg'),
                  tag: 'Fraxinus excelsior'
                },
              ]"
              :description="
                `Le frêne commun (Frexinus excelsior) est un arbre caduc, vigoureux, à port étalé, qui habite les forêts tempérées de toute l'Europe (sauf la région méditerranéenne) et de l'ouest de l'Asie. Essence qui aime la lumière, le frêne résiste très bien au froid ainsi qu'au bord de mer. Il croit de 10m en 20 ans en moyenne. C'est une essence très utilisée dans la réalisation de mobilier`
              "
            />
            </div>
            <div class="column is-4">
            <wood
              :title="'Ziricote'"
              :subtitle="'Juglans regia'"
              :images="[
                {
                  image: require('@/assets/images/creations/essences/europeennes/noyer/noyer1.jpg'),
                  tag: 'Juglans regia'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/noyer/noyer2.jpg'),
                  tag: 'Juglans regia'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/noyer/noyer3.jpg'),
                  tag: 'Juglans regia'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/noyer/noyer4.jpg'),
                  tag: 'Juglans regia'
                },
                {
                  image: require('@/assets/images/creations/essences/europeennes/noyer/noyer5.jpg'),
                  tag: 'Juglans regia'
                },
              ]"
              :description="
                `Il est veiné et coloré. Très réputé en ameublement et placage, il est également recherché en sculpture pour le contraste entre son aubier gris clair et son cœur brun foncé, ce dernier a néanmoins une teinte assez variable allant d'un blanc-grisâtre à un brun-gris à nuance beige ou violacée, suivant la provenance. Le bois de noyer est sans conteste le premier des bois indigènes d'ébénisterie, par la beauté de son veinage, sa stabilité et sa facilité d'usinage et polissage. Il est aussi utilisé en tournage, coutellerie et sculpture sur bois.`
              "
            />
            </div>
          </div>

          <!-- Autre cartes -->
        </div>
      </div>
    </section>

    <info-banner></info-banner>
  </section>
</template>

<script>
import InfoBanner from '@/components/partials/InfoBanner'
import Wood from '@/components/Wood'
export default {
  components: {
    InfoBanner,
    Wood
  }
}
</script>

<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0.253), rgba(0, 0, 0, 0.432)),
    url("../assets/images/general/bannieres/essences_tropicales-banner.jpg") no-repeat
      center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
