<template>
  <section>
    <section class="hero is-secondary has-bg-img is-medium">
      <div class="hero-body"></div>
    </section>

    <div class="box cta">
      <p class="has-text-centered"></p>
    </div>

    <section class="section lato">
      <div class="container">
        <div class="columns is-mobile is-multiline is-vcentered is-centered">
          <div class="column is-full is-offset-1">
            <div class="content">
              <h1 class="title is-size-2">L'histoire de La Contre-Pointe</h1>
            </div>
          </div>

          <!-- Section 1 -->
          <div class="column is-5">
            <figure class="image is-3by2">
              <img
                class="is-rounded"
                src="@/assets/images/general/pousse1.png"
                alt=""
              />
            </figure>
          </div>
          <div class="column is-offset-1 is-5 has-text-justified">
            <h1 class="title">Là où tout a commencé</h1>
            <p class="is-larger">
              <strong>Artisanat belge</strong>
            </p>
            <br />
            <p>
              J'ai toujours eu en moi cette envie de créer quelque chose de mes mains. C'est en ayant le plaisir
              de recevoir un tour à bois de la part de ma mère que j'ai pris pour la première fois en mains une gouge. Sur le moment ce fut comme une évidence, le travail du bois était une passion pour moi.
              Durant plusieurs semaines, je testais plusieurs formes, je m'entrainais à réaliser au mieux les créations d'autres tourneurs que je trouvais sur internet.
              Il manquait cependant à tout cela l'enseignement d'un professionnel pour combler les lacunes de l'autodidacte.
            </p>
            <br />
          </div>

          <!-- Section2 -->
          <div class="column is-5 has-text-justified">
            <h1 class="title">Mes stages avec Claude</h1>
            <p class="is-larger">
            </p>
            <br />
            <p>
              C'est encore une fois grâce à ma mère que j'ai rencontré Claude, mon maître de stage. Durant plusieurs journées, il m'a transmit une partie de ses connaissances. Lui-même étant tourneur depuis
              une dizaine d'années, il savait s'y prendre ! C'est dans son atelier à seulement quelques kilomètres de chez moi que j'ai appris à réaliser pléthore de stylos, de plats et que j'ai compris comment
              perfectionner mes mouvements.
            </p>
            <br />
          </div>
          <div class="column is-offset-1 is-5">
            <figure class="image is-3by2">
              <img
                class="is-rounded"
                src="@/assets/images/general/pousse2.png"
                alt=""
              />
            </figure>
          </div>

          <!-- Section 3 -->
          <div class="column is-5">
            <figure class="image is-3by2">
              <img
                class="is-rounded"
                src="@/assets/images/general/pousse3.png"
                alt=""
              />
            </figure>
          </div>
          <div class="column is-offset-1 is-5 has-text-justified">
            <h1 class="title">L'aube d'une belle aventure</h1>
            <p class="is-larger">
            </p>
            <br />
            <p>
              J'ai pour mes premières commandes été éncouragé par ma famille. Au moment de commencer, il est difficile de se faire connaître et c'est au début de l'activité qu'il faut investir le plus dans le matériel.
              Mais rapidement, grâce au bouche-à-oreille, J'ai pu obtenir plus de visibilité. À présent, j'ai ouvert ma boutique Etsy, j'ai mon propre site internet et cela sera une belle aventure qui promet
              d'être passionnante. J'espère que vous saurez ressentir à travers mes créations, tout le plaisir que je ressens en les réalisant.<br /><br />
              Damien, artisan de la Contre-Pointe.
            </p>
            <br />
          </div>
          <!-- Section 4 (video) -->
          <div class="column is-5 has-text-justified">
            <h1 class="title">Vidéo réalisée à l'atelier</h1>
            <p class="is-larger">
            </p>
            <br />
            <p>
              Celle vidéo a été réalisée à l'atelier. Il s'agit de la fabrication d'une toupie en bois de hêtre. Je vous invite à la visionner afin de mieux comprendre comment un objet est tourné !
            </p>
            <br />
          </div>
          <div class="column is-offset-1 is-5">
              <figure class="image">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ASSdbxt7l-E" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </figure>
          </div>
        </div>
      </div>
    </section>

    <info-banner></info-banner>
  </section>
</template>

<script>
import InfoBanner from '@/components/partials/InfoBanner'
export default {
  components: {
    InfoBanner
  }
}
</script>

<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("../assets/images/general/bannieres/bancop.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
