<template>
  <section class="section">
    <div class="box">
      <div class="columns is-centered">
        <div class="column is-8 has-text-centered">
          <h2 class="heading">Artisanat 100 % Belge</h2>
          <br />
          <div class="content">
            <figure class="image is-128x128 is-inline-block">
              <img class="is-rounded" src="@/assets/images/logos/logorondbleu.jpg" />
            </figure>
            <figure class="image is-128x128 is-inline-block">
              <img src="@/assets/images/general/drapeau_belge.png" />
            </figure>
            <figure class="image is-128x128 is-inline-block">
              <img class="is-rounded" src="@/assets/images/general/gifgif.gif" />
            </figure>
          </div>
          <!-- Mettre "La Contre-Pointe en jaune orangé" -->
          <p class="information-content subtitle">
            Je vous assure que chacun des produits que vous acheterez ici, à La Contre-Pointe par le site ou via la boutique Etsy sera réalisé dans mon atelier à Limal dans le Brabant Wallon ou à Sombreffe dans la province de Namur. Je mets
            un point d'honneur à vous garantir un produit de qualité. Chaque création doit pouvoir durer dans le temps, rien n'est jetable. Par ailleurs, j'aspire à travailler un maximum avec les essences locales.
            c'est avec ces principes forts que je continuerai de créer, en travaillant avec passion ce que la nature a de plus beau à nous offrir.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>
