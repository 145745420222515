<template>
  <section>
    <section class="hero is-secondary has-bg-img is-large">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">Plats et Bols en Bois Tournés à la Main</h1>
                  <!-- Peut être que les sous titres sont un peu trop petits... Faudrait augmenter leur taille. D'autre part, il serait sympa de préciser que dans les bols seront incrustés un petit logo de bronze en dessous-->
          <h2 class="subtitle heading">
            Les Plats et Bols présentés ci-dessous sont disponibles dans d'autres essences. N'hésitez pas à m'envoyer un message à la moindre question.
          </h2>
        </div>
      </div>
    </section>

    <div class="box cta">
      <p class="has-text-centered">
        <span class="tag is-red">Attention</span
        ><span class="p-5">
          Les modèles présentés ci-dessous sont des exemples (sauf exception). Votre projet se réalise sur commande et peut-être inspiré de ces exemples.
          <router-link to="contact">Plus d'infos</router-link></span
        >
      </p>
    </div>

    <section>
      <div class="container">
        <h1 class="title">Plats et Bols en Bois Tournés à la Main</h1>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><router-link to="creations">Catégories</router-link></li>
            <li class="is-active">
              <a href="#" aria-current="page">Plats et Bols</a>
            </li>
          </ul>
        </nav>

        <!-- Cartes objets -->
        <div class="columns is-multiline">
          <!-- Carte Plat Yser -->
          <div class="column is-4">
            <product
              :title="`Plat en Bois d'Érable - Yser`"
              :subtitle="'Plat Alimentaire'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/plats/1Yse.jpg'),
                  tag: 'Érable Sycomore',
                  price: '60 ',
                  description:
                    `Il mesure environs 5 centimètres de hauteur pour environs 20 centimètre de diamètre.
                    Il pèse environs 500 grammes en érable mais le poids varie en fonction de l'essence utilisée.`
                },
                {
                  image: require('@/assets/images/creations/plats/3Yse.jpg'),
                  tag: 'Érable Sycomore',
                  price: '60 ',
                  description:
                    `En tant que pièce unique, le plat a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                    Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.`
                },
                {
                  image: require('@/assets/images/creations/plats/4Yse.jpg'),
                  tag: 'Érable Sycomore',
                  price: '60 ',
                  description:
                    `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                    le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                    Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                    (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
                },
                {
                  image: require('@/assets/images/creations/plats/5Yse.jpg'),
                  tag: 'Érable Sycomore',
                  price: '60 ',
                  description:
                    ``
                },
                {
                  image: require('@/assets/images/creations/plats/6Yse.jpg'),
                  tag: 'Érable Sycomore',
                  price: '60 ',
                  description:
                    ``
                }
              ]"
              :summary="
                `Tourné à la main, ce plat d’Érable Sycomore a été enduit d'une huile de pépin de raisin afin de lui assurer une protection durable sur le long terme.
                Il convient naturellement aux utilisations alimentaires. L'Érable Sycomore est une espèce d'érable que l'on retrouve principalement en Europe.`
              "
            />
          </div>

          <!-- Carte Bol Amblève -->
          <div class="column is-4">
            <product
              :title="`Bol en Bois de Merisier - Amblève`"
              :subtitle="'Plat Alimentaire'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/plats/1Amb.jpg'),
                  tag: 'Merisier',
                  price: '30 ',
                  description:
                    `Il mesure environs 6 centimètres de hauteur pour environs 14 centimètre de diamètre.
                    Il pèse environs 200 grammes.`
                },
                {
                  image: require('@/assets/images/creations/plats/2Amb.jpg'),
                  tag: 'Merisier',
                  price: '30 ',
                  description:
                    `Le bol est également disponible sur commande dans d'autres essences. (N'hésitez pas à me contacter)`
                },
                {
                  image: require('@/assets/images/creations/plats/3Amb.jpg'),
                  tag: 'Merisier',
                  price: '30 ',
                  description:
                    `En tant que pièce unique, le bol a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                    Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.`
                },
                {
                  image: require('@/assets/images/creations/plats/4Amb.jpg'),
                  tag: 'Merisier',
                  price: '30 ',
                  description:
                    `Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout
                    mon savoir-faire et une précision de finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
                },
                {
                  image: require('@/assets/images/creations/plats/5Amb.jpg'),
                  tag: 'Merisier',
                  price: '30 ',
                  description:
                    `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                    le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                    Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                    (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
                }
              ]"
              :summary="
                `Tourné à la main, ce bol de Merisier (Cerisier Sauvage) a été fini à l'aide d'une huile naturelle afin de lui assurer une protection durable sur le long terme.
                Il convient naturellement aux utilisations alimentaires. Ce Cerisier est une espèce que l'on retrouve principalement en Europe.`
              "
            />
          </div>
          <!-- Carte Plat Semois -->
          <div class="column is-4">
            <product
              :title="`Plat en Bois de Noyer - Semois`"
              :subtitle="'Plat Alimentaire'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/plats/1Sem.jpg'),
                  tag: 'Noyer',
                  price: '55 ',
                  description:
                    `Il mesure environs 5 centimètres de hauteur pour environs 19 centimètre de diamètre.
                    Il pèse environs 80 grammes en Noyer mais le poids varie en fonction de l'essence.
                    Le plat est également disponible sur commande dans d'autres essences. (N'hésitez pas à me contacter)`
                },
                {
                  image: require('@/assets/images/creations/plats/2Sem.jpg'),
                  tag: 'Noyer',
                  price: '55 ',
                  description:
                    `En tant que pièce unique, ce plat a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                    Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.`
                },
                {
                  image: require('@/assets/images/creations/plats/3Sem.jpg'),
                  tag: 'Noyer',
                  price: '55 ',
                  description:
                    `Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout mon savoir-faire et une
                    précision de finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
                },
                {
                  image: require('@/assets/images/creations/plats/4Sem.jpg'),
                  tag: 'Noyer',
                  price: '55 ',
                  description:
                    `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                    le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                    Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                    (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
                }
              ]"
              :summary="
                `Tourné à la main, ce plat de Noyer a été enduit d'une huile de pépin de raisin afin de lui assurer une protection durable sur le long terme.
                Il convient naturellement aux utilisations alimentaires. Le Noyer que j'utilise provient d'Europe.`
              "
            />
          </div>

          <!-- Carte Plat Dyle -->
          <div class="column is-4">
            <product
              :title="`Plat en Bois de Hêtre Tourné - Dyle`"
              :subtitle="'Plat Alimentaire'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/plats/2Dyl.jpg'),
                  tag: 'Hêtre',
                  price: '50 ',
                  description:
                    `Il mesure environs 7 centimètres de hauteur pour environs 20 centimètre de diamètre. Il pèse environs 250 grammes en érable mais le poids varie en fonction de l'essence utilisée.`
                },
                {
                  image: require('@/assets/images/creations/plats/3Dyl.jpg'),
                  tag: 'Hêtre',
                  price: '50 ',
                  description:
                    `En tant que pièce unique, le plat a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                    Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.`
                },
                {
                  image: require('@/assets/images/creations/plats/4Dyl.jpg'),
                  tag: 'Hêtre',
                  price: '50 ',
                  description:
                    `Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout mon savoir-faire et une
                    précision de finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
                },
                {
                  image: require('@/assets/images/creations/plats/5Dyl.jpg'),
                  tag: 'Hêtre',
                  price: '50 ',
                  description:
                    `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                    le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                    Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                    (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
                },
                {
                  image: require('@/assets/images/creations/plats/6Dyl.jpg'),
                  tag: 'Hêtre',
                  price: '50 ',
                  description:
                    ``
                }
              ]"
              :summary="
                `Tourné à la main, ce plat de Hêtre a été enduit d'une huile de pépin de raisin afin de lui assurer une protection durable sur le long terme. Il convient naturellement aux utilisations alimentaires. Ce Hêtre est une espèce que l'on retrouve principalement en Europe.`
              "
            />
          </div>

          <!-- Carte Plat Meuse -->
          <div class="column is-4">
            <product
              :title="`Bol en Bois de Padouk - Meuse`"
              :subtitle="'Plat Décoratif'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/plats/1Meu.jpg'),
                  tag: 'Padouk',
                  price: '40 ',
                  description:
                    `Il mesure environs 5 centimètres de hauteur pour environs 15 centimètre de diamètre.
                    Il pèse environs 200 grammes en érable mais le poids varie en fonction de l'essence utilisée (Me contacter si vous désirez une variante).`
                },
                {
                  image: require('@/assets/images/creations/plats/2Meu.jpg'),
                  tag: 'Padouk',
                  price: '40 ',
                  description:
                    `En tant que pièce unique, le plat a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                    Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.
                    Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout mon savoir-faire et une précision de
                    finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
                },
                {
                  image: require('@/assets/images/creations/plats/3Meu.jpg'),
                  tag: 'Padouk',
                  price: '40 ',
                  description:
                    `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                    le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                    Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                    (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
                }
              ]"
              :summary="
                `Tourné à la main, ce plat de Padouk a été enduit d'une huile de pépin de raisin afin de lui assurer une protection durable sur le long terme. Le Padouk est une espèce que l'on retrouve exclusivement en Afrique.`
              "
            />
          </div>

          <!-- Carte Plat Escaut -->
          <div class="column is-4">
            <product
              :title="`Plat en Bois de Chêne - Escaut`"
              :subtitle="'Plat Alimentaire'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/plats/1Esc.jpg'),
                  tag: 'Chêne',
                  price: '20 ',
                  description:
                    `Il mesure environs 4,5 centimètres de hauteur pour environs 9,5 centimètre de diamètre. Il pèse 82 grammes.`
                },
                {
                  image: require('@/assets/images/creations/plats/2Esc.jpg'),
                  tag: 'Chêne',
                  price: '20 ',
                  description:
                    `En tant que pièce unique, le bol a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                    Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.
                    Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout mon savoir-faire et une précision de
                    finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
                },
                {
                  image: require('@/assets/images/creations/plats/3Esc.jpg'),
                  tag: 'Chêne',
                  price: '20 ',
                  description:
                    `Chaque pièce est unique, il est normal que le bol que vous aurez reçu ne sois pas décoré exactement des mêmes nervures/nœuds au même endroit que sur le bol présenté en photo.`
                },
                {
                  image: require('@/assets/images/creations/plats/4Esc.jpg'),
                  tag: 'Chêne',
                  price: '20 ',
                  description:
                    `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                    le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                    Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                    (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
                }
              ]"
              :summary="
                `Tourné à la main, ce bol de Chêne Européen a été fini à la cire afin de lui assurer une protection durable sur le long terme. Il convient naturellement aux utilisations alimentaires. Ce Chêne est une espèce que l'on retrouve principalement en Europe.`
              "
            />
          </div>

          <!-- Autre cartes -->
        </div>
      </div>
    </section>

    <info-banner></info-banner>
  </section>
</template>

<script>
import InfoBanner from '@/components/partials/InfoBanner'
import Product from '@/components/Product'
export default {
  components: {
    InfoBanner,
    Product
  }
}
</script>

<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0.253), rgba(0, 0, 0, 0.432)),
    url("../assets/images/creations/plats/3Bocbanner.jpg") no-repeat
      center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
