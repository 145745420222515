<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <div class="columns is-centered">
        <div class="column is-2">
          <router-link to="about">À propos</router-link>
        </div>
        <div class="column is-2">
          <router-link to="creations">Créations</router-link>
        </div>
        <div class="column is-2">
          <router-link to="contact">Contact</router-link>
        </div>
      </div>

      <div class="columns is-vcentered is-centered">
        <div class="column is-1">
          <a href="https://www.facebook.com/LaContrePointe">
            <span class="icon">
              <i class="mdi mdi-36px mdi-facebook"></i>
            </span>
          </a>
        </div>
        <div class="column is-1">
          <a href="https://www.instagram.com/lacontrepointe/">
            <span class="icon">
              <i class="mdi mdi-36px mdi-instagram"></i>
            </span>
          </a>
        </div>
        <div class="column is-1">
          <a href="https://www.etsy.com/fr/shop/LaContrePointe">
            <span class="icon">
              <i class="mdi mdi-36px mdi-alpha-e-box-outline"></i>
            </span>
          </a>
        </div>
      </div>

      <div class="columns is-vcentered is-centered">
        <div class="column">
          <strong>La Contre-Pointe</strong> ©2020<br />
          All Rights Reserved
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>
