import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Creations from '@/views/Creations'
import Contact from '@/views/Contact'
import About from '@/views/About'
import Stylos from '@/views/Stylos'
import Pendules from '@/views/Pendules'
import Bijoux from '@/views/Bijoux.vue'
import Toupies from '@/views/Toupies'
import Xylotheque from '@/views/Xylotheque'
import Europe from '@/views/Europe.vue'
import Tropicale from '@/views/Tropicale.vue'
import Plats from '@/views/Plats.vue'
import Maintenance from '@/views/Maintenance.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/creations',
    name: 'Creations',
    component: Creations
  },
  {
    path: '/xylotheque',
    name: 'Xylotheque',
    component: Xylotheque
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/europe',
    name: 'Europe',
    component: Europe
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '/tropicale',
    name: 'Tropicale',
    component: Tropicale
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/toupies',
    name: 'Toupies',
    component: Toupies
  },
  {
    path: '/pendules',
    name: 'Pendules',
    component: Pendules
  },
  {
    path: '/bijoux',
    name: 'Bijoux',
    component: Bijoux
  },
  {
    path: '/plats',
    name: 'Plats',
    component: Plats
  },
  {
    path: '/stylos',
    name: 'Stylos',
    component: Stylos
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
