<template>
  <section>
    <section class="hero is-secondary has-bg-img is-small">
      <div class="hero-body">
        <div class="columns is-centered is-vcentered">
          <div class="column is-3">
            <div class="p-5 pt-2">
              <figure class="image">
                <img class="is-rounded" src="@/assets/images/logos/logotranspa.png" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="box cta">
      <p class="has-text-centered">
        <span class="tag is-secondary">Info</span
        ><span class="p-5">
          La plupart des conceptions présentées ci-dessous est réalisable sur commande.
          <router-link to="contact">Cliquez ici pour plus d'infos</router-link></span
        >
      </p>
    </div>

    <section>
      <div class="container">
        <span class="p-5"></span><h1 class="title lato">Créations</h1>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li class="is-active">
              <a href="#" aria-current="page" class="lato">Catégories</a>
            </li>
          </ul>
        </nav>

        <!-- Cartes catégories -->
        <div class="columns is-multiline lato">

          <!-- Carte Pendule -->
          <div class="column is-4">
            <category
              :title="'Pendules de Radiesthésie'"
              :image="require('@/assets/images/general/bol_pendentif.jpg')"
              :route="'pendules'"
              :description="`Cela fait maintenant plusieurs années que je réalise des pendules de radiesthésie. Autant pour des débutants dans le domaine que des passionnés ou professionnels. Pour les inventer, je m'inspire de livres de radiesthésie et d'energétique. Pour le choix des essences, c'est principalement votre inspiration qui entrera en jeu.`"
            />
          </div>

          <!-- Carte Stylos -->
          <div class="column is-4">
            <category
              :title="`Instruments d'Écriture`"
              :image="require('@/assets/images/general/styloicon.jpg')"
              :route="'stylos'"
              :description="`Retrouvez ici mes instruments d'écriture. Chacun d'entre eux est réalisé à la main à l'atelier. Je fabrique des stylos à bille, des stylos de type rollers et des plûmes.`"
            />
          </div>

          <!-- Carte Plats -->
          <div class="column is-4">
            <category
              :title="'Plats, Bols & Assiettes'"
              :image="require('@/assets/images/general/platicon.jpg')"
              :route="'Plats'"
              :description="`En plus d'être utiles dans le domaine culinaire, les plats peuvent faire de merveilleux objets de décoration.
              Je ne fabrique que des pièces en bois massif et les finitions utilisées pour protéger ces plats dépendront de l'usage que vous en ferez.`"
            />
          </div>

          <!-- Carte Toupies -->
          <div class="column is-4">
            <category
              :title="`L'Art de la Toupie`"
              :image="require('@/assets/images/creations/toupies/toupie1.jpg')"
              :route="'maintenance'"
              :description="`Vous souhaitez offrir à un collectionneur, à vos amis ou à votre famille une toupie d'exception ? Celles que je fabrique sont composées de plusieurs bois européens ou exotiques précieux et sont designées à l'atelier !`"
            />
          </div>

          <!-- Carte Bijoux -->
          <div class="column is-4">
            <category
              :title="'Bijoux'"
              :image="require('@/assets/images/creations/pendentifs/pendentif_brun.jpg')"
              :route="'maintenance'"
              :description="''"
            />
          </div>

          <!-- Autre cartes -->
        </div>
      </div>
    </section>

    <!-- Bandeau informatif -->
    <info-banner></info-banner>
  </section>
</template>

<script>
import InfoBanner from '@/components/partials/InfoBanner'
import Category from '@/components/Category'

export default {
  components: {
    InfoBanner,
    Category
  }
}
</script>

<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.288)),
    url("~@/assets/images/general/bannieres/banatelier.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
