var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_vm._m(0),_c('div',{staticClass:"box cta"},[_c('p',{staticClass:"has-text-centered"},[_c('span',{staticClass:"tag is-red"},[_vm._v("Attention")]),_c('span',{staticClass:"p-5"},[_vm._v(" Les modèles présentés ci-dessous sont des exemples (sauf exception). Votre projet se réalise sur commande et peut-être inspiré de ces exemples. "),_c('router-link',{attrs:{"to":"contact"}},[_vm._v("Plus d'infos")])],1)])]),_c('section',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v("Stylos et Instruments d'Écriture")]),_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"creations"}},[_vm._v("Catégories")])],1),_vm._m(1)])]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Stylo à Bille - Monteverde`,"subtitle":'Système «Bouton Poussoire»',"subproducts":[
              {
                image: require('@/assets/images/creations/stylos/slim/slim_regroupcroix.jpg'),
                tag: 'Cormier / Mondzo / Acajou Amer',
                price: '30 à 40',
                availability: 'Disponible sur commande',
                description:
                  `Le stylo Monteverde est le seul slim vendu à La Contre-Pointe. Après de nombreux tests, j'ai remarqué que la plupart des stylos fins
              présents sur le marché étaient de mauvaise qualité. Des mécanismes peu cher qui ne font ni honneur au bois, ni honneur aux acheteurs de ceux-ci. C'est pourquoi,...`
              },
              {
                image: require('@/assets/images/creations/stylos/slim/slim_regroup.jpg'),
                tag: 'Cormier / Mondzo / Acajou Amer',
                price: '35 à 45',
                description:
                  `A la Contre-Pointe, j'ai pris le temps de trouver des mécanismes de grande qualité pour vous offrir le meilleur. Que les stylos soient fins, ou plus épais.`
              },
              {
                image: require('@/assets/images/creations/stylos/slim/slim_mondzo.jpg'),
                tag: 'Mondzo',
                price: '40',
                description:
                  `J'ai choisi pour accompagner ce stylo à bille un bois de Mondzo de première qualité car
                   son veinage possède beaucoup de caractère. Je l'ai allié avec ce mécanisme de couleur anthracite pour son élégence.`
              },
              {
                image: require('@/assets/images/creations/stylos/slim/slim_cormier.jpg'),
                tag: 'Cormier',
                price: '35',
                description:
                  `Le Cormier ressemblant beaucoup au poirier et possédant une teinte très claire, j'ai choisi de l'associer avec un mécanisme de finition matte pour lui donner un aspect des plus contemporains.`
              },
              {
                image: require('@/assets/images/creations/stylos/slim/slim_acajouamer.jpg'),
                tag: 'Acajou Amer',
                price: '45',
                description:
                  `Pour un bois précieux comme l'Acajou Amer, un mécanisme chromé est le choix parfait ! Une certaine élégence en émane tout en gardant un coté naturel puisque la fibre du bois a gardé son relief.`
              }
            ],"summary":`Le stylo Monteverde combine légereté et élégance. Etant plus fin que les autres, cela lui offre la qualité d'être moins lourd.
              Un confort différent par rapport aux stylos plus lourds considérés comme plus stables. Tout est une question de goût !
              Il s'agit d'un mécanisme de type click, il suffit d'une simple pression sur le dessus du stylo pour faire sortir ou rentrer la mine.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":'Stylo Roller - Sherwood',"subtitle":'Plaqué Or 10K',"subproducts":[
              {
                image: require('@/assets/images/creations/stylos/sherwood/stylo_erable_negundo_a.jpg'),
                tag: `Résine Acrylique avec Inclusion d'Érable Negundo`,
                price: '75',
                description:
                  `Ce stylo est tout simplement une réalisation d'exception. Il est composé d'un mécanisme plaqué Or 10K. et de loupe d'Érable Negundo mis en résine par un
                  processus de stabilisation sous-vide. La finition est d'un beau rendu mat`
              },
              {
                image: require('@/assets/images/creations/stylos/sherwood/stylo_erable_negundo_b.jpg'),
                tag: `Résine Acrylique avec Inclusion d'Érable Negundo`,
                price: '75',
                description:
                  `Ce stylo est tout simplement une réalisation d'exception. Il est composé d'un mécanisme plaqué Or 10K. et de loupe d'Érable Negundo mis en résine par un
                  processus de stabilisation sous-vide. La finition est d'un beau rendu mat`
              },
                              {
                image: require('@/assets/images/creations/stylos/sherwood/Stylo_olivier_a.jpg'),
                tag: `Olivier de Provence`,
                price: '55',
                description:
                  `Ce Stylo est composé d'un mécanisme chromé et de bois d'Olivier de Provence. Il est accompagné d'une finition brillante très résistante à l'utilisation.`
              },
              {
                image: require('@/assets/images/creations/stylos/sherwood/Stylo_olivier_b.jpg'),
                tag: `Olivier de Provence`,
                price: '55',
                description:
                  `Ce Stylo est composé d'un mécanisme chromé et de bois d'Olivier de Provence. Il est accompagné d'une finition brillante très résistante à l'utilisation.`
              }
            ],"summary":`Le Stylo Roller du modèle Sherwood est tout ce que l'on peut rechercher en matière de qualité. Il combine élégence, sobriété, stabilité, durabilité, simplicité d'utilisation,
              ainsi qu'un fini d'un éclat exceptionnel. Si vous êtes à la recherche d'un classique d'utilisation tout en vous trouvant dans le haut de gamme, vous trouverez le Sherwood incomparable
              aux produits réalisés à la chaîne.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":'Stylo à Bille - Tijuca',"subtitle":'Doré mat',"subproducts":[
              {
                image: require('@/assets/images/creations/stylos/tijuca/stylo_palissandre_du_honduras_a.jpg'),
                tag: `Palissandre du Honduras`,
                price: '55',
                description:
                  `J'ai choisi un segment de Palissandre du honduras pour ce stylo. Cette essence précieuse a la particularité d'être très dure et durable.
                  Je voulais laisser le bois mat, la finition se limite donc à un passage sur le bois avec un chiffon imbibé d'huile de pépin de raisin.`
              },
              {
                image: require('@/assets/images/creations/stylos/tijuca/stylo_palissandre_du_honduras_b.jpg'),
                tag: `Palissandre du Honduras`,
                price: '55',
                description:
                  `J'ai choisi un segment de Palissandre du honduras pour ce stylo. Cette essence précieuse a la particularité d'être très dure et durable.
                  Je voulais laisser le bois mat, la finition se limite donc à un passage sur le bois avec un chiffon imbibé d'huile de pépin de raisin.`
              }
            ],"summary":`Le Stylo à bille Tijuca est d'une élégance rare. De grande qualité, il conviendra à tous ceux qui veulent obtenir une grande stabilité d'écriture. Il est très durable et simple d'utilisation.
              Il s'agit d'un mécanisme de type twist, il suffit d'un simple mouvement de vissage pour faire rentrer ou sortir la mine.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":'Stylo à Bille - Tollymore',"subtitle":'Chromé, mécanisme de type click',"subproducts":[
              {
                image: require('@/assets/images/creations/stylos/Tollymore/Stylo_wenge_a.jpg'),
                tag: `Wengé`,
                price: '50',
                description:
                  `J'ai sélectionné un morceau de Wengé pour ce stylo chromé. Cette essence a la particularité d'avoir une couleur comparable au chocolat. C'est un bois strié dont
                  la fibre apparait d'autant plus sur ce modèle qui n'est fini qu'à l'huile pour lui laisser un aspect naturel. le clicker du mécanisme est d'une qualité exceptionnelle.`
              },
              {
                image: require('@/assets/images/creations/stylos/Tollymore/stylo_wenge_b.jpg'),
                tag: `Wengé`,
                price: '50',
                description:
                  `J'ai sélectionné un morceau de Wengé pour ce stylo chromé. Cette essence a la particularité d'avoir une couleur oscillant entre un noir profond et un couleur comparable au chocolat. C'est un bois strié dont
                  la fibre apparait d'autant plus sur ce modèle qui n'est fini qu'à l'huile de pépin de raisin pour lui laisser un aspect naturel. le clicker du mécanisme est d'une qualité exceptionnelle.`
              }
            ],"summary":`Le Stylo à bille Tollymore est pourvu d'un design plus brute. De grande qualité, il conviendra à n'importe qui. Il est très durable et simple d'utilisation.
              Il s'agit d'un mécanisme de type click, il suffit d'une simple pression sur le dessus du stylo pour faire sortir ou rentrer la mine.`}})],1)])])]),_c('info-banner')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-secondary has-bg-img is-large"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container has-text-centered"},[_c('h1',{staticClass:"title"},[_vm._v("Instruments d'Écriture")]),_c('h2',{staticClass:"subtitle heading"},[_vm._v(" Stylos Roller, à bille, à plume, en Bois, Tournés à La Main. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"is-active"},[_c('a',{attrs:{"href":"#","aria-current":"page"}},[_vm._v("Instruments d'écriture")])])
}]

export { render, staticRenderFns }