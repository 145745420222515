<template>
  <b-navbar class="is-secondary " transparent fixed-top spaced shadow>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <h1 class="title has-text-cream">La Contre-Pointe</h1>
      </b-navbar-item>
    </template>
    <template slot="start">
      <figure class="image is-inline-block is-64x64">
        <img class="is-rounded" src="@/assets/images/logos/logotranspa.png" />
      </figure>
    </template>

    <template slot="end">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <b-icon class="p-5" icon="home" size="is-medium"></b-icon>
        <span class="bree">Accueil</span>
      </b-navbar-item>
      <b-navbar-item class="is-paddingless">
        <span>|</span>
      </b-navbar-item>

      <b-navbar-item tag="router-link" :to="{ path: '/creations' }">
        <b-icon class="p-5" icon="hand-saw" size="is-medium"></b-icon>
        <span class=" is-inline-block bree">Creations</span>
      </b-navbar-item>
      <b-navbar-item class="is-paddingless">
        <span>|</span>
      </b-navbar-item>

      <b-navbar-item tag="router-link" :to="{ path: '/xylotheque' }">
        <b-icon
          class="p-5"
          icon="book-open-page-variant"
          size="is-medium"
        ></b-icon>
        <span class="bree">La Xylothèque</span>
      </b-navbar-item>
      <b-navbar-item class="is-paddingless">
        <span>|</span>
      </b-navbar-item>

      <b-navbar-item tag="router-link" :to="{ path: '/contact' }">
        <b-icon class="p-5" icon="email" size="is-medium"></b-icon>
        <span class="bree">Contact</span>
      </b-navbar-item>
      <b-navbar-item class="is-paddingless">
        <span>|</span>
      </b-navbar-item>

      <b-navbar-item tag="router-link" :to="{ path: '/about' }">
        <b-icon class="p-5" icon="tree" size="is-medium"></b-icon>
        <span class="bree">À propos</span>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
