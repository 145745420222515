<template>
  <section>
    <div class="card clickable" @click="isCardModalActive = true">
      <div class="card-image has-text-centered">
        <b-carousel :autoplay="false" :pause-info="false">
          <b-carousel-item v-for="(item, i) in images" :key="i">
            <figure class="image is-3by2">
              <img :src="item.image" />
            </figure>
          </b-carousel-item>
        </b-carousel>
      </div>
      <div class="card-content">
        <div class="content">
          <h4 class="title">{{ title }}</h4>
          <p>
            {{ description }}
          </p>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <b-modal v-model="isCardModalActive" :width="800" scroll="keep">
      <div class="card">
        <div class="card-image">
          <b-carousel :autoplay="false" :pause-info="false">
            <b-carousel-item v-for="(item, i) in images" :key="i">
              <figure class="image is-3by2">
                <img :src="item.image" />
              </figure>
            </b-carousel-item>
          </b-carousel>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title">{{ title }}</p>
              <p class="subtitle is-6">{{ subtitle }}</p>
            </div>
            <div class="media-right">
              <b-tag size="is-medium" type="is-primary">{{ currentTag }}</b-tag>
            </div>
          </div>
          <div class="content">
            {{ description }}
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: ['title', 'subtitle', 'images', 'description'],
  computed: {},
  data () {
    return {
      isCardModalActive: false,
      currentTag: null
    }
  },
  methods: {
    getCurrentTag (value) {
      this.currentTag = this.images[value].tag
    }
  },
  mounted () {
    this.getCurrentTag(0)
  }
}
</script>

<style lang="scss" scoped></style>
