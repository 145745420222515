<template>
  <section style="height: 100%;">
    <div class="card clickable card_hoverable" @click="$router.push(route)" style="height: 100%;">
      <div class="card-image has-text-centered">
        <figure class="image is-square">
          <img :src="image" />
        </figure>
      </div>
      <div class="card-content " >
        <div class="content has-text-centered">
          <h4>{{ title }}</h4>
          <p class="has-text-justified">
            {{ description }}
          </p>
          <b-button type="is-secondary" >
            Découvrir
          </b-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['title', 'image', 'route', 'description']
}
</script>
