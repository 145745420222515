<template>
  <section>
    <section class="hero is-secondary has-bg-img is-large">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">Pendules</h1>
          <h2 class="subtitle heading">
            Pendules de radiesthésie, tournés à la main.
          </h2>
        </div>
      </div>
    </section>

    <div class="box cta">
      <p class="has-text-centered">
        <span class="tag is-red">Attention</span>
        <span class="p-5"
          >Les modèles présentés ci-dessous sont des exemples (sauf exception). Votre projet se réalise sur commande et peut-être inspiré de ces exemples.
          <router-link to="contact">Plus d'infos</router-link></span
        >
      </p>
    </div>

    <section>
      <div class="container">
        <h1 class="title">Les Pendules</h1>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><router-link to="creations">Catégories</router-link></li>
            <li class="is-active">
              <a href="#" aria-current="page">Pendules</a>
            </li>
          </ul>
        </nav>

        <!-- Cartes objets -->
        <div class="columns">
        <div class="columns is-multiline">
          <!-- Carte Pendule Cocobolo -->
          <div class="column is-4">
            <product
              :title="'Pendule Icare'"
              :subtitle="'Disponible sur commande'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/pendules/pendule_cocobolo.jpg'),
                  tag: 'Cocobolo',
                  price: '45',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_buis.jpg'),
                  tag: 'Buis',
                  price: '35',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_hêtre.jpg'),
                  tag: 'Hêtre',
                  price: '30',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_ebene.jpg'),
                  tag: 'Ébène',
                  price: '35',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_hetre.jpg'),
                  tag: 'Hêtre Échauffé',
                  price: '35',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_padouk.jpg'),
                  tag: 'Padouk',
                  price: '35',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_maronnier.jpg'),
                  tag: 'Marronier',
                  price: '30',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                }
              ]"
              :summary="
                'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspirations est tirée de plusieurs livres de radiesthésie.'
              "
            />
          </div>

          <!-- Carte pendule goutte -->
          <div class="column is-4">
            <product
              :title="`Pendule Goutte d'Eau`"
              :subtitle="'Disponible sur commande'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/pendules/pendule_frene.jpg'),
                  tag: 'Frêne',
                  price: '30',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspirations est tirée de plusieurs livres de radiesthésie.'
                }
              ]"
              :summary="
                `Les significations symboliques de l'eau peuvent se réduire en trois thèmes dominants : source de vie, moyen de purification et centre de régénérescence.
Dans le Nouveau Testament elle est le symbole de la vie spirituelle et de l'esprit.
Le pendule 'Goutte d'eau' peut être qualifié d'universel car il s'adresse aussi bien aux débutants qu'aux utilisateurs avertis ou confirmés et ses domaines d'applications sont larges dans le domaine de la radiesthésie`
              "
            />
          </div>

          <!-- Carte pendule Noisette -->
          <div class="column is-4">
            <product
              :title="`Pendule Noisette`"
              :subtitle="'Disponible sur commande'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/pendules/pendule_lilas.jpg'),
                  tag: 'Lilas Commun',
                  price: '30',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspirations est tirée de plusieurs livres de radiesthésie.'
                }
              ]"
              :summary="
                `Le Pendule noisette est une création entièrement personnelle. Il s'agit d'une variante plus petite du pendule Icare ayant une forme suggérant une noisette.
                Il saura vous séduire par son côté naturel. Réalisé exclusivement en bois de Lilas Belge récolté dans mon jardin et séché directement à mon atelier, ce bois possède un charme tout particulier, un veinage très présent et une grande durabilité.`
              "
            />
          </div>
                    <!-- Carte pendule inspiration égyptienne -->
          <div class="column is-4">
            <product
              :title="`Pendule d'Inspiration Egyptienne`"
              :subtitle="'Disponible sur commande'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/pendules/pendule_bi.jpg'),
                  tag: 'Ebène du Gabon et Erable Sycomore',
                  price: '40',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspirations est tirée de plusieurs livres de radiesthésie.'
                }
              ]"
              :summary="
                `Le Pendule noisette est une création entièrement personnelle. Il s'agit d'une variante plus petite du pendule Icare ayant une forme suggérant une noisette.
                Il saura vous séduire par son côté naturel. Réalisé exclusivement en bois de Lilas Belge récolté dans mon jardin et séché directement à mon atelier, ce bois possède un charme tout particulier, un veinage très présent et une grande durabilité.`
              "
            />
          </div>

          <!-- Autre cartes -->
        </div>
        </div>
      </div>
    </section>

    <info-banner></info-banner>
  </section>
</template>

<script>
import InfoBanner from '@/components/partials/InfoBanner'
import Product from '@/components/Product'
export default {
  components: {
    InfoBanner,
    Product
  }
}
</script>

<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0.253), rgba(0, 0, 0, 0.432)),
    url("../assets/images/general/bannieres/banpend.jpg") no-repeat center
      center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
