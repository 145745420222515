<template>
  <section>
    <section class="hero is-secondary has-bg-img is-medium">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title lato">La Xylothèque</h1>
          <h2 class="subtitle heading">
            Voici l'ensemble des essences disponibles à l'Atelier. Utile pour choisir le bois qui accompagnera votre projet.
          </h2>
        </div>
      </div>
    </section>

    <div class="box cta">
      <p class="has-text-centered">
        <span class="tag is-red">Attention</span
        ><span class="p-5">
          Le veinage et la couleur du bois varieront toujours par rapport aux photos en fonction de l'environnement dans lequel l'arbre aura grandi, de son âge, du morceau selectionné,...
          <router-link to="contact">Contactez-moi pour plus d'infos</router-link></span>
      </p>
    </div>

    <section>
      <div class="container">
        <h1 class="title"></h1>
        <nav class="breadcrumb" aria-label="breadcrumbs">
        </nav>

        <!-- Cartes Catégories -->
        <div class="columns is-multiline">
          <!-- Carte Essences Européennes-->

          <div class="column is-4">
            <category
              :title="'Essences Européennes'"
              :image="
                require('@/assets/images/general/essences_europe.jpg')
              "
              :route="'europe'"
              :description="`Les Essences Européennes nous entourent à chaque fois que nous nous promenons en forêt. Hêtre, Chêne, Frêne,... Sont les arbres qui rappellent le paysage de nos campagnes.
              Ils ont un charme inexpliquable, souvent une grande robustesse et offrent à nos régions de merveilleux paysages. En voici une selection que j'utilise à l'atelier.`"
            />
          </div>

          <!-- Carte Essences Tropicales -->
          <div class="column is-4">
            <category
              :title="'Essences Tropicales'"
              :image="require('@/assets/images/general/essences_tropicales.jpg')"
              :route="'maintenance'"
              :description="`Les Essences Tropicales proviennent comme leur nom l'indique des régions tropicales comme l'Amérique Centrale, l'Amérique du Sud, l'Afrique,
              l'Indonésie et le nord de l'Australie. Les bois que l'on y trouve sont souvent riches en couleur. Il en existe une très grande variété, beaucoup d'entre eux sont très denses et possèdent un fini exceptionnel.`"
            />
          </div>

          <!-- Carte Essences Précieuses -->
          <div class="column is-4">
            <category
              :title="'Essences Précieuses'"
              :image="require('@/assets/images/general/essences_precieuses.jpg')"
              :route="'maintenance'"
              :description="`Les Bois Précieux sont des essences dont le qualificatif fait référence à leurs propriétés, leur rareté et leur prix. Ils sont la plupart du temps utilisés
              en ébénisterie, lutherie, marqueterie ou sculpture. Contrairement à ce que l'on pourrait penser, ils ne sont pas forcément qu'exotiques, ils sont répartis aux quatres coins
              du monde et c'est aussi grâce à cela que les bois précieux sont si recherchés. J'en utilise à l'atelier lors de la création de pendules de radiesthésie, de pendentifs, de stylos,...`"
            />
          </div>

          <!-- Carte Autres Matières -->
          <div class="column is-4">
            <category
              :title="'Autres Matières'"
              :image="
                require('@/assets/images/general/autres_matieres.png')
              "
              :route="'maintenance'"
              :description="'Noix de Banksia, Noix de Tagua, Résines, Epoxy, Poussière de bois,... Tant de matières alternatives pouvant servir à mettre en valeur certains projets en tournage sur bois.'"
            />
          </div>

          <!-- Autre cartes -->
        </div>
      </div>
    </section>

    <!-- Bandeau informatif -->
    <info-banner></info-banner>
  </section>
</template>

<script>
import InfoBanner from '@/components/partials/InfoBanner'
import Category from '@/components/Category'

export default {
  components: {
    InfoBanner,
    Category
  }
}
</script>

<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.288)),
    url("~@/assets/images/general/bannieres/boisbanniere.jpg") no-repeat center center
      fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
