<template>
  <section>
    <section class="hero is-secondary has-bg-img is-medium">
      <div class="hero-body"></div>
    </section>

    <div class="box cta">
      <p class="has-text-centered"></p>
    </div>

    <section class="section">
      <div class="container">
        <div class="columns is-multiline is-vcentered">
          <div class="column is-5 has-text-justified">
            <h1 class="title">Contact</h1>
            <p class="is-larger">
              <strong
                >Pour commander, vous pouvez m'envoyer un e-mail ou vous rendre
                sur ma page Etsy.</strong
              >
            </p>
            <br />
            <p>
              La plupart des réalisations visibles sur le site ou sur Etsy sert d'exemple. Je réalise surtout vos projets
              personnalisées en fonction de vos préférences. Pour cela, joignez
              dans l'e-mail votre demande, nous rentrerons alors en
              contact afin que nous puissions peaufiner au mieux votre
              commande !<br /><br />
              Damien Madessis, Tourneur sur Bois à la Contre-Pointe.
            </p>
            <br />

            <div class="columns">
              <div class="column">
                <span class="icon">
                  <i class="mdi mdi-phone"></i>
                </span>
                <p class="heading is-inline">
                  <strong>Téléphone</strong>
                </p>
                <p class="subheading">+32 479.44.57.70</p>
              </div>
              <div class="column">
                <span class="icon">
                  <i class="mdi mdi-at"></i>
                </span>
                <p class="heading is-inline">
                  <strong>Email</strong>
                </p>
                <p class="subheading">lacontrepointe@gmail.com</p>
              </div>
              <div class="column">
                <span class="icon">
                  <i class="mdi mdi-cart"></i>
                </span>
                <p class="heading is-inline">
                  <strong>Etsy</strong>
                </p>
                <p class="subheading">
                  <a href="https://www.etsy.com/fr/shop/LaContrePointe"
                    >Commander sur Etsy
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="column is-6 is-offset-1">
            <img
              class="is-rounded"
              src="@/assets/images/creations/pendentifs/pendentif4.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <info-banner></info-banner>
  </section>
</template>

<script>
import InfoBanner from '@/components/partials/InfoBanner'
export default {
  components: {
    InfoBanner
  }
}
</script>

<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("../assets/images/general/bannieres/bancop.jpg") no-repeat center
      center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
