var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_vm._m(0),_c('div',{staticClass:"box cta"},[_c('p',{staticClass:"has-text-centered"},[_c('span',{staticClass:"tag is-red"},[_vm._v("Attention")]),_c('span',{staticClass:"p-5"},[_vm._v(" Les modèles présentés ci-dessous sont des exemples (sauf exception). Votre projet se réalise sur commande et peut-être inspiré de ces exemples. "),_c('router-link',{attrs:{"to":"contact"}},[_vm._v("Plus d'infos")])],1)])]),_c('section',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v("Plats et Bols en Bois Tournés à la Main")]),_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"creations"}},[_vm._v("Catégories")])],1),_vm._m(1)])]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Plat en Bois d'Érable - Yser`,"subtitle":'Plat Alimentaire',"subproducts":[
              {
                image: require('@/assets/images/creations/plats/1Yse.jpg'),
                tag: 'Érable Sycomore',
                price: '60 ',
                description:
                  `Il mesure environs 5 centimètres de hauteur pour environs 20 centimètre de diamètre.
                  Il pèse environs 500 grammes en érable mais le poids varie en fonction de l'essence utilisée.`
              },
              {
                image: require('@/assets/images/creations/plats/3Yse.jpg'),
                tag: 'Érable Sycomore',
                price: '60 ',
                description:
                  `En tant que pièce unique, le plat a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                  Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.`
              },
              {
                image: require('@/assets/images/creations/plats/4Yse.jpg'),
                tag: 'Érable Sycomore',
                price: '60 ',
                description:
                  `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                  le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                  Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                  (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
              },
              {
                image: require('@/assets/images/creations/plats/5Yse.jpg'),
                tag: 'Érable Sycomore',
                price: '60 ',
                description:
                  ``
              },
              {
                image: require('@/assets/images/creations/plats/6Yse.jpg'),
                tag: 'Érable Sycomore',
                price: '60 ',
                description:
                  ``
              }
            ],"summary":`Tourné à la main, ce plat d’Érable Sycomore a été enduit d'une huile de pépin de raisin afin de lui assurer une protection durable sur le long terme.
              Il convient naturellement aux utilisations alimentaires. L'Érable Sycomore est une espèce d'érable que l'on retrouve principalement en Europe.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Bol en Bois de Merisier - Amblève`,"subtitle":'Plat Alimentaire',"subproducts":[
              {
                image: require('@/assets/images/creations/plats/1Amb.jpg'),
                tag: 'Merisier',
                price: '30 ',
                description:
                  `Il mesure environs 6 centimètres de hauteur pour environs 14 centimètre de diamètre.
                  Il pèse environs 200 grammes.`
              },
              {
                image: require('@/assets/images/creations/plats/2Amb.jpg'),
                tag: 'Merisier',
                price: '30 ',
                description:
                  `Le bol est également disponible sur commande dans d'autres essences. (N'hésitez pas à me contacter)`
              },
              {
                image: require('@/assets/images/creations/plats/3Amb.jpg'),
                tag: 'Merisier',
                price: '30 ',
                description:
                  `En tant que pièce unique, le bol a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                  Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.`
              },
              {
                image: require('@/assets/images/creations/plats/4Amb.jpg'),
                tag: 'Merisier',
                price: '30 ',
                description:
                  `Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout
                  mon savoir-faire et une précision de finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
              },
              {
                image: require('@/assets/images/creations/plats/5Amb.jpg'),
                tag: 'Merisier',
                price: '30 ',
                description:
                  `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                  le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                  Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                  (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
              }
            ],"summary":`Tourné à la main, ce bol de Merisier (Cerisier Sauvage) a été fini à l'aide d'une huile naturelle afin de lui assurer une protection durable sur le long terme.
              Il convient naturellement aux utilisations alimentaires. Ce Cerisier est une espèce que l'on retrouve principalement en Europe.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Plat en Bois de Noyer - Semois`,"subtitle":'Plat Alimentaire',"subproducts":[
              {
                image: require('@/assets/images/creations/plats/1Sem.jpg'),
                tag: 'Noyer',
                price: '55 ',
                description:
                  `Il mesure environs 5 centimètres de hauteur pour environs 19 centimètre de diamètre.
                  Il pèse environs 80 grammes en Noyer mais le poids varie en fonction de l'essence.
                  Le plat est également disponible sur commande dans d'autres essences. (N'hésitez pas à me contacter)`
              },
              {
                image: require('@/assets/images/creations/plats/2Sem.jpg'),
                tag: 'Noyer',
                price: '55 ',
                description:
                  `En tant que pièce unique, ce plat a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                  Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.`
              },
              {
                image: require('@/assets/images/creations/plats/3Sem.jpg'),
                tag: 'Noyer',
                price: '55 ',
                description:
                  `Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout mon savoir-faire et une
                  précision de finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
              },
              {
                image: require('@/assets/images/creations/plats/4Sem.jpg'),
                tag: 'Noyer',
                price: '55 ',
                description:
                  `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                  le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                  Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                  (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
              }
            ],"summary":`Tourné à la main, ce plat de Noyer a été enduit d'une huile de pépin de raisin afin de lui assurer une protection durable sur le long terme.
              Il convient naturellement aux utilisations alimentaires. Le Noyer que j'utilise provient d'Europe.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Plat en Bois de Hêtre Tourné - Dyle`,"subtitle":'Plat Alimentaire',"subproducts":[
              {
                image: require('@/assets/images/creations/plats/2Dyl.jpg'),
                tag: 'Hêtre',
                price: '50 ',
                description:
                  `Il mesure environs 7 centimètres de hauteur pour environs 20 centimètre de diamètre. Il pèse environs 250 grammes en érable mais le poids varie en fonction de l'essence utilisée.`
              },
              {
                image: require('@/assets/images/creations/plats/3Dyl.jpg'),
                tag: 'Hêtre',
                price: '50 ',
                description:
                  `En tant que pièce unique, le plat a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                  Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.`
              },
              {
                image: require('@/assets/images/creations/plats/4Dyl.jpg'),
                tag: 'Hêtre',
                price: '50 ',
                description:
                  `Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout mon savoir-faire et une
                  précision de finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
              },
              {
                image: require('@/assets/images/creations/plats/5Dyl.jpg'),
                tag: 'Hêtre',
                price: '50 ',
                description:
                  `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                  le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                  Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                  (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
              },
              {
                image: require('@/assets/images/creations/plats/6Dyl.jpg'),
                tag: 'Hêtre',
                price: '50 ',
                description:
                  ``
              }
            ],"summary":`Tourné à la main, ce plat de Hêtre a été enduit d'une huile de pépin de raisin afin de lui assurer une protection durable sur le long terme. Il convient naturellement aux utilisations alimentaires. Ce Hêtre est une espèce que l'on retrouve principalement en Europe.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Bol en Bois de Padouk - Meuse`,"subtitle":'Plat Décoratif',"subproducts":[
              {
                image: require('@/assets/images/creations/plats/1Meu.jpg'),
                tag: 'Padouk',
                price: '40 ',
                description:
                  `Il mesure environs 5 centimètres de hauteur pour environs 15 centimètre de diamètre.
                  Il pèse environs 200 grammes en érable mais le poids varie en fonction de l'essence utilisée (Me contacter si vous désirez une variante).`
              },
              {
                image: require('@/assets/images/creations/plats/2Meu.jpg'),
                tag: 'Padouk',
                price: '40 ',
                description:
                  `En tant que pièce unique, le plat a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                  Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.
                  Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout mon savoir-faire et une précision de
                  finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
              },
              {
                image: require('@/assets/images/creations/plats/3Meu.jpg'),
                tag: 'Padouk',
                price: '40 ',
                description:
                  `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                  le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                  Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                  (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
              }
            ],"summary":`Tourné à la main, ce plat de Padouk a été enduit d'une huile de pépin de raisin afin de lui assurer une protection durable sur le long terme. Le Padouk est une espèce que l'on retrouve exclusivement en Afrique.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Plat en Bois de Chêne - Escaut`,"subtitle":'Plat Alimentaire',"subproducts":[
              {
                image: require('@/assets/images/creations/plats/1Esc.jpg'),
                tag: 'Chêne',
                price: '20 ',
                description:
                  `Il mesure environs 4,5 centimètres de hauteur pour environs 9,5 centimètre de diamètre. Il pèse 82 grammes.`
              },
              {
                image: require('@/assets/images/creations/plats/2Esc.jpg'),
                tag: 'Chêne',
                price: '20 ',
                description:
                  `En tant que pièce unique, le bol a été entièrement réalisé à la main au sein de mon atelier localisé en Belgique dans la province de Namur.
                  Je mets un point d'honneur à vous garantir une qualité irréprochable tant par ma passion que par le savoir-faire de l'artisanat qui en découle.
                  Les plats de ce genre ornent nos intérieurs durant de longues années. Ils méritent en conséquence que je leur accorde tout mon savoir-faire et une précision de
                  finition irréprochable. C'est avec ce principe fort que je continuerai de créer, en sublimant avec dévotion ce que mère nature a de plus beau à nous offrir.`
              },
              {
                image: require('@/assets/images/creations/plats/3Esc.jpg'),
                tag: 'Chêne',
                price: '20 ',
                description:
                  `Chaque pièce est unique, il est normal que le bol que vous aurez reçu ne sois pas décoré exactement des mêmes nervures/nœuds au même endroit que sur le bol présenté en photo.`
              },
              {
                image: require('@/assets/images/creations/plats/4Esc.jpg'),
                tag: 'Chêne',
                price: '20 ',
                description:
                  `Entretien: Ne pas mettre au lave-vaisselle - Ne pas mettre au micro-ondes - Évitez
                  le contact direct avec l'eau si vous désirez garder cet aspect de finition. Une patine s'installera naturellement avec le temps et l'utilisation.
                  Cela est tout à fait naturel. - N'hésitez pas à passer sur le plat une couche d'huile alimentaire et incolore si vous ne voulez pas teindre le bois
                  (Tournesol, Colza, Pépin de raisin, Citron,...) pour lui rendre ses couleurs d'origine.`
              }
            ],"summary":`Tourné à la main, ce bol de Chêne Européen a été fini à la cire afin de lui assurer une protection durable sur le long terme. Il convient naturellement aux utilisations alimentaires. Ce Chêne est une espèce que l'on retrouve principalement en Europe.`}})],1)])])]),_c('info-banner')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-secondary has-bg-img is-large"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container has-text-centered"},[_c('h1',{staticClass:"title"},[_vm._v("Plats et Bols en Bois Tournés à la Main")]),_c('h2',{staticClass:"subtitle heading"},[_vm._v(" Les Plats et Bols présentés ci-dessous sont disponibles dans d'autres essences. N'hésitez pas à m'envoyer un message à la moindre question. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"is-active"},[_c('a',{attrs:{"href":"#","aria-current":"page"}},[_vm._v("Plats et Bols")])])
}]

export { render, staticRenderFns }