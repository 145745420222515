var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_vm._m(0),_c('div',{staticClass:"box cta"},[_c('p',{staticClass:"has-text-centered"},[_c('span',{staticClass:"tag is-red"},[_vm._v("Attention")]),_c('span',{staticClass:"p-5"},[_vm._v("Les modèles présentés ci-dessous sont des exemples (sauf exception). Votre projet se réalise sur commande et peut-être inspiré de ces exemples. "),_c('router-link',{attrs:{"to":"contact"}},[_vm._v("Plus d'infos")])],1)])]),_c('section',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v("Bijoux")]),_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"creations"}},[_vm._v("Catégories")])],1),_vm._m(1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":'Toupie Jouvence',"subtitle":'En Hêtre échauffé, Ebène du Gabon, Ziricote, Redheart et Ebène du Mozambique,',"subproducts":[
              {
                image: require('@/assets/images/creations/toupies/toupie_1.jpg'),
                tag: 'Sur commande',
                price: '35',
                description:
                  'Cette toupie a été réalisée par mes soins pour une commande.'
              },
            ],"summary":`Les essences selectionnées pour cette toupie sont le hêtre échauffé, l'Ebène du Gabon, le Ziricote, le Redheart, et l'Ebène du Mozambique.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":'Toupie Aube',"subtitle":'En argent',"subproducts":[
              {
                image: require('@/assets/images/creations/toupies/toupie_2.jpg'),
                tag: 'Ebène',
                price: '35',
                description:
                  'Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec. Integer enim neque volutpat actincidunt vitae semper quis. Accumsan tortor posuere ac utconsequat semper viverra nam.'
              },
            ],"summary":'Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec. Integer enim neque volutpat actincidunt vitae semper quis. Accumsan tortor posuere ac utconsequat semper viverra nam.'}})],1)])])]),_c('info-banner')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-secondary has-bg-img is-large"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container has-text-centered"},[_c('h1',{staticClass:"title"},[_vm._v("Bijoux")]),_c('h2',{staticClass:"subtitle heading"},[_vm._v(" Les bijoux que je tourne sont réalisées à partir de différentes essences ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"is-active"},[_c('a',{attrs:{"href":"#","aria-current":"page"}},[_vm._v("Bijoux")])])
}]

export { render, staticRenderFns }