<template>
  <section class="hero is-secondary has-bg-img is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">

        <div class="columns is-centered is-multiline">
          <div class="column is-4">
            <figure class="image is-inline-block">
              <img class="is-rounded" src="@/assets/images/logos/logotranspa.png" />
            </figure>
          </div>
        </div>

        <h1 class="title is-size-1 p-5">La Contre-Pointe</h1>
            <figure class="image is-inline-block">
              <img src="@/assets/images/general/fabrication_1.png" />
            </figure>

        <h2 class="subtitle is-size-3 p-1"></h2>
        <h2 class="subtitle is-size-3 p-5 benne">
          Pour me présenter en quelques lignes, je m’appelle Damien,
          je suis un jeune tourneur sur bois passionné.

          La Contre-Pointe est le nom de mon atelier dans lequel je confectionnais à l’origine de petits objets pour mes amis et ma famille.
          À présent, je souhaite partager avec vous ma vision de l'artisanat et vous faire découvrir à travers mes objets,
          mon univers de copeaux et d'odeurs boisées.
        </h2>

        <b-button
          @click="$router.push('/creations')"
          type="is-cream is-large has-text-dark"
        >
          <span class="bree">Créations</span>
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
export default {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url("../assets/images/general/bannieres/carrelets_deux.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
