<template>
  <section style="height: 100%">
    <div class="card clickable card_hoverable" style="height: 100%">
      <div class="card-image has-text-centered">
        <b-carousel with-carousel-list :pause-info="false" :indicator="false">
          <b-carousel-item v-for="(item, i) in subproducts" :key="i">
            <figure class="image is-3by2" @click="isCardModalActive = true">
              <img :src="item.image" />
            </figure>
          </b-carousel-item>

          <template slot="list" slot-scope="props">
            <b-carousel-list
              v-model="props.active"
              :data="subproducts"
              @switch="props.switch($event, false)"
              as-indicator
            />
          </template>
        </b-carousel>
      </div>
      <div class="card-content" @click="isCardModalActive = true">
        <div class="content">
          <h4 class="title">{{ title }}</h4>
          <p>
            {{ summary }}
          </p>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <b-modal v-model="isCardModalActive" :width="800" scroll="keep">
      <div class="card">
        <div class="card-image">
          <b-carousel
            @change="getCurrentAttributes"
            :autoplay="false"
            with-carousel-list
            :indicator="false"
          >
            <b-carousel-item v-for="(item, i) in subproducts" :key="i">
              <figure class="image is-3by2">
                <img :src="item.image" />
              </figure>
            </b-carousel-item>

            <template slot="list" slot-scope="props">
              <b-carousel-list
                v-model="props.active"
                :data="subproducts"
                @switch="props.switch($event, false)"
                as-indicator
              />
            </template>
          </b-carousel>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-4 is-marginless pb-1">{{ title }}</p>
              <p class="subtitle is-6 is-marginless">{{ subtitle }}</p>
              <div v-if="currentTag" class="field pt-2">
                <b-tag size="is-medium" type="is-primary">
                  {{ currentTag }}
                </b-tag>
              </div>
            </div>
            <div class="media-right has-text-right">
              <div v-if="currentPrice" class="field">
                <b-tag class="has-text-black" size="is-medium" type="is-secondary"
                  >{{ currentPrice }}€
                </b-tag>
              </div>
              <div v-if="currentAvailability" class="field">
                <b-tag class="is-italic" size="is-medium" type="is-gray">
                  {{ currentAvailability }}
                </b-tag>
              </div>
            </div>
          </div>
          <div class="content">
            {{ currentDescription }}
          </div>
          <div class="has-text-right">
            <b-button
              class="has-text-black"
              type="is-secondary"
              @click="$router.push('/contact')"
            >
              Commander
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
export default {
  props: ['title', 'subtitle', 'summary', 'subproducts'],
  computed: {},
  data () {
    return {
      isCardModalActive: false,
      currentTag: null,
      currentPrice: null,
      currentAvailability: null,
      currentDescription: null
    }
  },
  methods: {
    getCurrentAttributes (value) {
      this.currentTag = this.subproducts[value].tag
      this.currentPrice = this.subproducts[value].price
      this.currentAvailability = this.subproducts[value].availability
      this.currentDescription = this.subproducts[value].description
    }
  },
  mounted () {
    this.getCurrentAttributes(0)
  }
}
</script>

<style lang="scss" scoped></style>
