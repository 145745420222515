<template>
  <section>
    <section class="hero is-secondary has-bg-img is-large">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">L'Art de la Toupie</h1>
          <h2 class="subtitle heading">
            Les toupies que je tourne sont réalisées à partir de différentes essences
          </h2>
        </div>
      </div>
    </section>
        <!-- Ca serait super une petite étiquette "Disponible immédiatement"-->
    <div class="box cta">
      <p class="has-text-centered">
        <span class="tag is-red">Attention</span>
        <span class="p-5"
          >Les modèles présentés ci-dessous sont des exemples (sauf exception). Votre projet se réalise sur commande et peut-être inspiré de ces exemples.
          <router-link to="contact">Plus d'infos</router-link></span
        >
      </p>
    </div>

    <section>
      <div class="container">
        <h1 class="title">Toupies</h1>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><router-link to="creations">Catégories</router-link></li>
            <li class="is-active">
              <a href="#" aria-current="page">Toupies</a>
            </li>
          </ul>
        </nav>

        <!-- Cartes objets -->
        <div class="columns">
          <!-- Carte Toupie -->
          <div class="column is-4">
            <product
              :title="'Toupie Artémis'"
              :subtitle="'Disponible sur commande'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/toupies/toupie_1.jpg'),
                  tag: 'En Hêtre échauffé, Ebène du Gabon, Ziricote, Redheart et Ebène du Mozambique,',
                  price: '35',
                  description:
                    'Cette toupie a été réalisée par mes soins pour une commande.'
                },
              ]"
              :summary="
                `Les essences selectionnées pour cette toupie sont le hêtre échauffé, l'Ebène du Gabon, le Ziricote, le Redheart, et l'Ebène du Mozambique.`
              "
            />
          </div>

          <!-- Carte Toupie 2 -->
          <div class="column is-4">
            <product
              :title="'Toupie Apollon'"
              :subtitle="'Disponible sur commande'"
              :subproducts="[
                {
                  image: require('@/assets/images/creations/toupies/toupie_2.jpg'),
                  tag: 'Ebène du Gabon, Ivoire Brun et Padouk',
                  price: '35',
                  description:
                    `Cette toupie a été tournée à partir d'Ebène du Gabon, d'un bois que l'on appelle Ivoire Brun (Bridelia Micrantha) et de Padouk`
                },
              ]"
              :summary="
                'Purus semper eget duis at tellus at urna condimentum mattis. Non blandit massa enim nec. Integer enim neque volutpat actincidunt vitae semper quis. Accumsan tortor posuere ac utconsequat semper viverra nam.'
              "
            />
          </div>

          <!-- Autre cartes -->
        </div>
      </div>
    </section>

    <info-banner></info-banner>
  </section>
</template>

<script>
import InfoBanner from '@/components/partials/InfoBanner'
import Product from '@/components/Product'
export default {
  components: {
    InfoBanner,
    Product
  }
}
</script>

<style lang="scss" scoped>
.has-bg-img {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/images/general/bannieres/banniere_toupie.jpg") no-repeat center
      center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
