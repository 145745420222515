var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_vm._m(0),_c('div',{staticClass:"box cta"},[_c('p',{staticClass:"has-text-centered"},[_c('span',{staticClass:"tag is-red"},[_vm._v("Attention")]),_c('span',{staticClass:"p-5"},[_vm._v("Les modèles présentés ci-dessous sont des exemples (sauf exception). Votre projet se réalise sur commande et peut-être inspiré de ces exemples. "),_c('router-link',{attrs:{"to":"contact"}},[_vm._v("Plus d'infos")])],1)])]),_c('section',[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title"},[_vm._v("Les Pendules")]),_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"creations"}},[_vm._v("Catégories")])],1),_vm._m(1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":'Pendule Icare',"subtitle":'Disponible sur commande',"subproducts":[
                {
                  image: require('@/assets/images/creations/pendules/pendule_cocobolo.jpg'),
                  tag: 'Cocobolo',
                  price: '45',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_buis.jpg'),
                  tag: 'Buis',
                  price: '35',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_hêtre.jpg'),
                  tag: 'Hêtre',
                  price: '30',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_ebene.jpg'),
                  tag: 'Ébène',
                  price: '35',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_hetre.jpg'),
                  tag: 'Hêtre Échauffé',
                  price: '35',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_padouk.jpg'),
                  tag: 'Padouk',
                  price: '35',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                },
                {
                  image: require('@/assets/images/creations/pendules/pendule_maronnier.jpg'),
                  tag: 'Marronier',
                  price: '30',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspiration est tirée de plusieurs livres de radiesthésie.'
                }
              ],"summary":'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspirations est tirée de plusieurs livres de radiesthésie.'}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Pendule Goutte d'Eau`,"subtitle":'Disponible sur commande',"subproducts":[
                {
                  image: require('@/assets/images/creations/pendules/pendule_frene.jpg'),
                  tag: 'Frêne',
                  price: '30',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspirations est tirée de plusieurs livres de radiesthésie.'
                }
              ],"summary":`Les significations symboliques de l'eau peuvent se réduire en trois thèmes dominants : source de vie, moyen de purification et centre de régénérescence.
Dans le Nouveau Testament elle est le symbole de la vie spirituelle et de l'esprit.
Le pendule 'Goutte d'eau' peut être qualifié d'universel car il s'adresse aussi bien aux débutants qu'aux utilisateurs avertis ou confirmés et ses domaines d'applications sont larges dans le domaine de la radiesthésie`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Pendule Noisette`,"subtitle":'Disponible sur commande',"subproducts":[
                {
                  image: require('@/assets/images/creations/pendules/pendule_lilas.jpg'),
                  tag: 'Lilas Commun',
                  price: '30',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspirations est tirée de plusieurs livres de radiesthésie.'
                }
              ],"summary":`Le Pendule noisette est une création entièrement personnelle. Il s'agit d'une variante plus petite du pendule Icare ayant une forme suggérant une noisette.
                Il saura vous séduire par son côté naturel. Réalisé exclusivement en bois de Lilas Belge récolté dans mon jardin et séché directement à mon atelier, ce bois possède un charme tout particulier, un veinage très présent et une grande durabilité.`}})],1),_c('div',{staticClass:"column is-4"},[_c('product',{attrs:{"title":`Pendule d'Inspiration Egyptienne`,"subtitle":'Disponible sur commande',"subproducts":[
                {
                  image: require('@/assets/images/creations/pendules/pendule_bi.jpg'),
                  tag: 'Ebène du Gabon et Erable Sycomore',
                  price: '40',
                  description:
                    'Les pendules Icares sont idéaux pour se lancer dans la radiesthésie. Plusieurs modèles sont disponibles et leur inspirations est tirée de plusieurs livres de radiesthésie.'
                }
              ],"summary":`Le Pendule noisette est une création entièrement personnelle. Il s'agit d'une variante plus petite du pendule Icare ayant une forme suggérant une noisette.
                Il saura vous séduire par son côté naturel. Réalisé exclusivement en bois de Lilas Belge récolté dans mon jardin et séché directement à mon atelier, ce bois possède un charme tout particulier, un veinage très présent et une grande durabilité.`}})],1)])])])]),_c('info-banner')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-secondary has-bg-img is-large"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container has-text-centered"},[_c('h1',{staticClass:"title"},[_vm._v("Pendules")]),_c('h2',{staticClass:"subtitle heading"},[_vm._v(" Pendules de radiesthésie, tournés à la main. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"is-active"},[_c('a',{attrs:{"href":"#","aria-current":"page"}},[_vm._v("Pendules")])])
}]

export { render, staticRenderFns }